/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-14 11:12:48
 * @LastEditors: xiaoxin
 * @LastEditTime: 2021-12-07 09:58:19
 */
import { favoriteAction, likeAction , getBehavior , relayAction ,parentRead } from '@/services/api/AppManager/cms-user-data';
import { themeDetailListG } from '@/services/api/AppManager/calendar-app';

export interface TopicFormat extends APP_API.Topic {
  questionNumber: number;
  evaDescArr:string[];
}
export type themeDetailState = {
  topic:APP_MANAGER_API.Topic
  behavior:APP_MANAGER_API.Behavior;
  evaActionCoureseList:APP_MANAGER_API.ThemeIntroductionItem[]
};

interface ModelType {
  namespace: string;
  state: themeDetailState;
  effects: {
    getthemeDetailListG: Effect;
    getBehavior: Effect;
    doFavoriteAction:Effect;
    doLikeAction:Effect;
    doRelayAction:Effect;
    parentReadAction:Effect;
  };
  reducers: {
    setState: Reducer<themeDetailState>;
  };
}

const ThemeDetailListModelG: ModelType = {
  namespace: 'themeDetailListModelG',
  state: {
    topic:{
      id:'',
      title:'',
      description:'',
      evaId:'',
      evaSubtitle:'',
      evaDesc:'',
      coverImage: '',
      tags:[],
    },
    evaActionCoureseList:[],
    behavior:{
      like: 0,
      doLike: false,
      favorite: 0,
      doFavorite:false,
      relay:0,
      doRelay:false,
    }
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getthemeDetailListG({ payload, callback }, { call, put }) {
      try {
        const result = yield call(themeDetailListG, payload.id);
        yield put({
          type: 'setState',
          payload: {
            topic:result.topic,
            evaActionCoureseList:result.contentVOS
          },
        });

      } catch (error) {}
    },
    *getBehavior({ payload, callback }, { call, put }){
      try {
        const result = yield call(getBehavior, payload.targetId);
        yield put({
          type: 'setState',
          payload: {
            behavior: result,
          },
        });
      } catch (error) {}
    },
    *doFavoriteAction({ payload, callback }, { call, put }){
      try {
        yield call(favoriteAction, payload);
        yield put({type: 'getBehavior',payload: payload});
      } catch (error) {}
    },
    *doLikeAction({ payload, callback }, { call, put }){
      try {
        yield call(likeAction, payload);
        yield put({type: 'getBehavior',payload: payload});
      } catch (error) {}
    },
    *doRelayAction({ payload, callback }, { call, put }){
      try {
        yield call(relayAction, payload);
        yield put({type: 'getBehavior',payload: payload});
      } catch (error) {}
    },
    *parentReadAction({ payload, callback }, { call, put }){
      try {
        yield call(parentRead, payload);
      } catch (error) {}
    }
  },
};

export default ThemeDetailListModelG;