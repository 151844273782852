/*
 * @Description: cms-user-data接口
 * @Author: tianxin
 * @Date: 2021-07-15 14:47:48
 * @LastEditors: tianxin
 * @LastEditTime: 2021-07-23 20:03:57
 */

import { enumGetKey } from '@/utils'
import { request } from '@/utils/request'

const baseUrl = '/cms-user-data'

enum ApiCmsUserData {
  '/content/user/like/teacher',
  '/content/user/favorite/teacher',
  '/content/user/relay/teacher',
  '/content/behavior/target/teacher',
  '/content/user/admin',
}

/**
 * 获取点赞收藏转发
 */

export async function getBehavior(targetId: string) {
	return request<APP_API.behavior>(`${baseUrl}/content/behavior/target/teacher/${targetId}`, {
		method: 'GET',
	})
}

/**
 * 收藏
 */
 export async function favoriteAction(data:APP_MANAGER_PARAMS.doBehavior) {
  return request(
    enumGetKey(ApiCmsUserData, '/content/user/favorite/teacher', baseUrl),
    {
      method: 'POST',
      data,
    },
  );
}
/**
 * 点赞
 */
 export async function likeAction(data:APP_MANAGER_PARAMS.doBehavior) {
  return request(
    enumGetKey(ApiCmsUserData, '/content/user/like/teacher', baseUrl),
    {
      method: 'POST',
      data,
    },
  );
}
/**
 * 转发
 */
 export async function relayAction(data:APP_MANAGER_PARAMS.doBehavior) {
  return request(
    enumGetKey(ApiCmsUserData, '/content/user/relay/teacher', baseUrl),
    {
      method: 'POST',
      data,
    },
  );
}

/**
 * 主题阅读数
 */
 export async function parentRead(data:APP_MANAGER_PARAMS.parentReadOption) {
  return request(
    enumGetKey(ApiCmsUserData, '/content/user/admin', baseUrl),
    {
      method: 'POST',
      data,
    },
  );
}