/*
 * @Description:
 * @Author: heyat
 * @Date: 2021-07-20 11:24:43
 * @LastEditors: 你的名字
 * @LastEditTime: 2021-07-28 16:55:28
 */
import { request } from '@/utils/request'
const baseUrl = '/calendar-app'

/**
 * 获取家访报告信息
 */
 export async function getPersonalInfo(params: any) {
	return request<APP_MANAGER_PARAMS.PersonalInfo>(`${baseUrl}/home/visit/record/personal`, {
		method: 'GET',
    params
  })}
/**
* 我收到的通知列表getPublishedNotifications
*/
export async function getReceivedNotifications(params: APP_PARAMS.GetCommonList) {
	return request(`${baseUrl}/notice/user/received/list`, {
		method: 'get',
		params
	})
}

/**
* 我发布的通知列表
*/
export async function getPublishedNotifications(params: APP_PARAMS.GetCommonList) {
	return request(`${baseUrl}/notice/user/release/list`, {
		method: 'get',
		params
	})
}

/**
* 通知详情
*/
export async function getNotificationDetail(params: APP_PARAMS.GetNotificationDetail) {
	return request(`${baseUrl}/notice/info`, {
		method: 'get',
		params
	})
}

/**
* 通知确认统计
*/
export async function getNotificationStatistics(params: APP_PARAMS.GetNotificationStatistics) {
	return request(`${baseUrl}/notice/statistics`, {
		method: 'get',
		params
	})
}

/**
* 新建通知
*/
export async function createNewNotifi(data: Record<string, any>) {
	return request(`${baseUrl}/notice`, {
		method: 'post',
		data
	})
}

/**
* 通知提醒
*/
export async function remind(params: APP_PARAMS.Remind) {
	return request(`${baseUrl}/notice/remind`, {
		method: 'get',
		params
	})
}

/**
 * 获取班级报告信息
 */
 export async function getClassInfo(params: any) {
	return request<APP_MANAGER_PARAMS.ClassInfo>(`${baseUrl}/home/visit/report/class`, {
		method: 'GET',
    params
	})
}


/**
 * 获取主题详情列表（测评+活动+文章）
 */
 export async function themeDetailListG(id: string) {
	return request(`${baseUrl}/admin/topic/${id}`, {
		method: 'GET',
	})
}
/**
* 获取内容详情
*/
export async function getAdminContent(id: string) {
	return request<APP_MANAGER_PARAMS.ActivityDetail>(`${baseUrl}/admin/content/${id}`, {
		method: 'get',
	})
 }

 /**
* 获取班级统计
*/
export async function getEvaStatistics(id: string) {
	return request(`${baseUrl}/parent/topic/eva/statistics/list`, {
		method: 'get',
		params: {
			topicId: id
		}
	})
 }
