/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: tianxin
 * @LastEditTime: 2021-07-28 17:24:41
 */

import { getHomeVisitDetailClass } from '@/services/api/AppManager/dhapi';



export type schoolHomeVisitDetailState = {
    //学校-班级家访详情
    homeVisitDetailClass:APP_MANAGER_API.ClassHomeVisitDetailItem[];
    //学校-班级家访详情总条数
    totalNum:number
};

interface ModelType {
  namespace: string;
  state: schoolHomeVisitDetailState;
  effects: {
    getHomeVisitDetailClass:Effect;
  };
  reducers: {
    setState: Reducer<schoolHomeVisitDetailState>;
  };
}
//分页数据
let schoolData:APP_MANAGER_API.ClassHomeVisitDetailItem[] = [];

const SchoolHomeVisitDetailModel: ModelType = {
  namespace: 'schoolHomeVisitDetailModel',
  state: {
    homeVisitDetailClass:[],
    totalNum:0
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getHomeVisitDetailClass({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitDetailClass, payload);
         if(payload.pageNum === 1){
          schoolData = result.rows
         }else{
          schoolData = schoolData.concat(result.rows)
         }
        yield put({
          type: 'setState',
          payload: {
            homeVisitDetailClass:schoolData,
            totalNum:result.totalNum ? result.totalNum : 0
          },
        });
        callback && callback()
        
      } catch (error) {}
    }
  },
};

export default SchoolHomeVisitDetailModel;