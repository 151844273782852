/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-07-06 13:26:09
 * @LastEditors: jiaojinhui
 * @LastEditTime: 2022-02-08 09:51:35
 */
import { Toast } from 'antd-mobile'
import { extend, ResponseError } from 'umi-request'
import { getCookie } from '.'

const codeMessage: { [key: string]: any } = {
	200: '服务器成功返回请求的数据。',
	201: '新建或修改数据成功。',
	202: '一个请求已经进入后台排队（异步任务）。',
	204: '删除数据成功。',
	400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
	401: '用户没有权限（令牌、用户名、密码错误）。',
	403: '用户得到授权，但是访问是被禁止的。',
	404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
	406: '请求的格式不可得。',
	410: '请求的资源被永久删除，且不会再得到的。',
	422: '当创建一个对象时，发生一个验证错误。',
	500: '服务器发生错误，请检查服务器。',
	502: '网关错误。',
	503: '服务不可用，服务器暂时过载或维护。',
	504: '网关超时。',
}

const errorHandler = (error: ResponseErrorDesign) => {
	const { response, request, data } = error
	const { options } = request || {}
	const { showTips = true } = options || {}
	if (response && response.status && showTips && (!data || !data.code)) {
		const errorText = data.msg || codeMessage[response.status] || response.statusText
		Toast.info(errorText, 1)
		if ([401].includes(response.status)) {
		}
	} else if (!response && showTips) {
		console.log('error', error)
		// Toast.info('网络异常', 1);
	}
	throw error
	// return response;
}

const request = extend({
	prefix: '/api',
	timeout: 20000,
	errorHandler,
})

type MyHeaders = {
	Authorization: string
} & HeadersInit

interface ResponseMsg extends Response {
	code: string | number
	msg?: string
	error?: string
	// data: Record<string, unknown>;
	// 这是名不符实的，但是木有办法，类型要校验通过
	data: Response
}

interface ResponseErrorDesign extends ResponseError {
	request: any
}

const ALI = 'APPCODE a8f642e9b9ff4481baf2fc21b54432bc'
const FAUTH = 'Basic d3gtYWRtaW4tbWFuYWdlbWVudDp3eC1hZG1pbi1tYW5hZ2VtZW50LXB3'

request.interceptors.request.use((url, options) => {
	let token = (window as any).wxapp?.token || (getCookie('h5_token') as string)

	const cOptions = Object.assign({}, { ...options })
	cOptions.headers = {
		...options.headers,
		Authorization: '',
	}
	;(cOptions.headers as MyHeaders).Authorization = /^\/dhapi/.test(url) ? ALI : token ? token : FAUTH
	return {
		url: url,
		options: { ...cOptions },
	}
})

request.interceptors.response.use(async (response, options) => {
	const data: ResponseMsg = await response.clone().json()
	const { showTips = true, skipCode = true, showSuccess = false, headers } = options
	// 展示提示消息
	if (response.status >= 200 && response.status < 300 && data) {
		if (data.hasOwnProperty('code')) {
			if (data.code === 1) {
				if (showTips && showSuccess) {
					Toast.info(data.msg, 1)
				}
				return skipCode ? data.data : data
			} else {
				if (showTips) {
					Toast.info(data.msg, 1)
				} else {
					Toast.fail(data.msg)
				}
				// throw new Error('no data')
			}
		} else {
			return skipCode ? data.data : data
		}
	} else {
		if ((headers as MyHeaders).Authorization === 'APPCODE a8f642e9b9ff4481baf2fc21b54432bc') {
			//数仓接口 固定token
			return skipCode ? data.data : data
		} else {
			const { msg, error } = data
			msg ? Toast.info(msg) : Toast.info(error)
		}
	}
	throw response
	// return response;
})

export { request }
