// @ts-nocheck
import { lazy } from "react";

type childType = {
  path: string,
  component: React.LazyExoticComponent<React.FC<{}>>,
}
export type RouterType = {
  redirect?: boolean
  path: string,
  component?: React.LazyExoticComponent<any>,
  children?: childType[],
  notExect?: boolean,
}

const Router: RouterType[] = [
  {path: '/AppManager/CusService', component: lazy(()=>import('@/page/AppManager/CusService/index.tsx'))},
{path: '/AppManager/EducationHome/HomeVisit', component: lazy(()=>import('@/page/AppManager/EducationHome/HomeVisit/index.tsx'))},
{path: '/AppManager/EducationHome/HomeVisit/VisitDetail', component: lazy(()=>import('@/page/AppManager/EducationHome/HomeVisit/VisitDetail/index.tsx'))},
{path: '/AppManager/EducationHome/InstallDetail', component: lazy(()=>import('@/page/AppManager/EducationHome/InstallDetail/index.tsx'))},
{path: '/AppManager/FamilyDetail/ActivityDetail', component: lazy(()=>import('@/page/AppManager/FamilyDetail/ActivityDetail/index.tsx'))},
{path: '/AppManager/FamilyDetail/CourseDetail', component: lazy(()=>import('@/page/AppManager/FamilyDetail/CourseDetail/index.tsx'))},
{path: '/AppManager/FamilyVisit/FamilyVisieDes', component: lazy(()=>import('@/page/AppManager/FamilyVisit/FamilyVisieDes/index.tsx'))},
{path: '/AppManager/FamilyVisit', component: lazy(()=>import('@/page/AppManager/FamilyVisit/index.tsx'))},
{path: '/AppManager/Invite', component: lazy(()=>import('@/page/AppManager/Invite/index.tsx'))},
{path: '/AppManager/Invite/InviteSuccess', component: lazy(()=>import('@/page/AppManager/Invite/InviteSuccess.tsx'))},
{path: '/AppManager/Notification/CreateNew', component: lazy(()=>import('@/page/AppManager/Notification/CreateNew/index.tsx'))},
{path: '/AppManager/Notification/Detail', component: lazy(()=>import('@/page/AppManager/Notification/Detail/index.tsx'))},
{path: '/AppManager/Notification/SearchReceiver', component: lazy(()=>import('@/page/AppManager/Notification/SearchReceiver/index.tsx'))},
{path: '/AppManager/Notification/SelectReceiver', component: lazy(()=>import('@/page/AppManager/Notification/SelectReceiver/index.tsx'))},
{path: '/AppManager/Notification/Success', component: lazy(()=>import('@/page/AppManager/Notification/Success/index.tsx'))},
{path: '/AppManager/Notification/Tools', component: lazy(()=>import('@/page/AppManager/Notification/Tools/index.tsx'))},
{path: '/AppManager/Personal/Agreement/agreementTab', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/agreementTab.tsx'))},
{path: '/AppManager/Personal/Agreement/evaAgreement', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/evaAgreement.tsx'))},
{path: '/AppManager/Personal/Agreement/privacyAgreement', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/privacyAgreement.tsx'))},
{path: '/AppManager/Personal/Agreement/secrecyAgreement', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/secrecyAgreement.tsx'))},
{path: '/AppManager/Personal/Agreement/tutorAgreementTab', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/tutorAgreementTab.tsx'))},
{path: '/AppManager/Personal/Agreement/userAgreement', component: lazy(()=>import('@/page/AppManager/Personal/Agreement/userAgreement.tsx'))},
{path: '/AppManager/Report/ClassReport', component: lazy(()=>import('@/page/AppManager/Report/ClassReport/index.tsx'))},
{path: '/AppManager/Report/CooperateReport', component: lazy(()=>import('@/page/AppManager/Report/CooperateReport/index.tsx'))},
{path: '/AppManager/Report/FamilyReport', component: lazy(()=>import('@/page/AppManager/Report/FamilyReport/index.tsx'))},
{path: '/AppManager/Report/ParentsStudy', component: lazy(()=>import('@/page/AppManager/Report/ParentsStudy/index.tsx'))},
{path: '/AppManager/Report/PersonalReport', component: lazy(()=>import('@/page/AppManager/Report/PersonalReport/index.tsx'))},
{path: '/AppManager/SchoolHome/HomeVisit', component: lazy(()=>import('@/page/AppManager/SchoolHome/HomeVisit/index.tsx'))},
{path: '/AppManager/SchoolHome/HomeVisit/VisitDetail', component: lazy(()=>import('@/page/AppManager/SchoolHome/HomeVisit/VisitDetail/index.tsx'))},
{path: '/AppManager/SchoolHome', component: lazy(()=>import('@/page/AppManager/SchoolHome/index.tsx'))},
{path: '/AppManager/SchoolHome/InstallDetail', component: lazy(()=>import('@/page/AppManager/SchoolHome/InstallDetail/index.tsx'))},
{path: '/AppManager/TeacherHome', component: lazy(()=>import('@/page/AppManager/TeacherHome/index.tsx'))},
{path: '/AppManager/TeacherHome/LightEvaDes', component: lazy(()=>import('@/page/AppManager/TeacherHome/LightEvaDes/index.tsx'))},
{path: '/AppManager/TeacherHome/LightEvaIntroduction', component: lazy(()=>import('@/page/AppManager/TeacherHome/LightEvaIntroduction/index.tsx'))},
{path: '/AppManager/TeacherHome/ThemeDes', component: lazy(()=>import('@/page/AppManager/TeacherHome/ThemeDes/index.tsx'))},
{path: '/AppManager/TeacherHome/ThemeIntroduction', component: lazy(()=>import('@/page/AppManager/TeacherHome/ThemeIntroduction/index.tsx'))},
{path: '/AppManager/TeacherRegistration/download', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/download.tsx'))},
{path: '/AppManager/TeacherRegistration', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/index.tsx'))},
{path: '/AppManager/TeacherRegistration/joinTeam', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/joinTeam.tsx'))},
{path: '/AppManager/TeacherRegistration/privacyPolicy', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/privacyPolicy.tsx'))},
{path: '/AppManager/TeacherRegistration/result', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/result.tsx'))},
{path: '/AppManager/TeacherRegistration/serviceAgreement', component: lazy(()=>import('@/page/AppManager/TeacherRegistration/serviceAgreement.tsx'))}
]

export default Router;
