/*
 * @Description:
 * @Author: 你的名字
 * @Date: 2021-07-07 10:19:31
 * @LastEditors: hujiali
 * @LastEditTime: 2021-09-16 10:11:26
 */

// ******************************************************
// 注意： 此文件的执行环境是 .gene 或 .gene-production 目录
// ******************************************************

import { Suspense } from 'react'
/* @ts-ignore */
import App from './App'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
/* @ts-ignore */
import dvaModels from './models';
import dva from 'dva'
import 'lib-flexible'
import '@/assets/less/index.less'
const reactFastclick = require('react-fastclick')

reactFastclick()
const dvaInstance = dva()
// 注册models
dvaModels(dvaInstance)

/** 
 * 重写history对象 pushState replaceState方法，自定义pushState replaceState的事件监听 
 * */
/** ----------begin---------- */
function _wr(type: string) {
	const orig: Function = (window.history as any)[type]
	return function () {
		const rv = orig.apply(window.history, arguments)
		const e: any = new Event(type)
		e.arguments = arguments
		window.dispatchEvent(e)
		return rv
	}
}
;(window.history as any).pushState = _wr('pushState')
;(window.history as any).replaceState = _wr('replaceState')

// 因为默认是将root元素作为滚动盒子，页面跳转后还会保持之前的滚动高度，所以每次跳转页面要将scrollTop至为0
const root: HTMLElement = document.getElementById('root')!
window.addEventListener('popstate', () => {
	root.scrollTo(0, 0)
})
window.addEventListener('pushState', () => {
	root.scrollTo(0, 0)
})
window.addEventListener('replaceState', () => {
	root.scrollTo(0, 0)
})
/** ----------end---------- */

dvaInstance.router(() => (
	// <HelmetProvider>
		<BrowserRouter>
			{/* 使用了路由懒加载，所以需要使用<Suspense>包起来 */}
			<Suspense fallback={<div></div>}>
				<Switch>
					<Route
						path='/'
						render={(routerProps) => {
							return <App {...routerProps} />
						}}
					/>
				</Switch>
			</Suspense>
		</BrowserRouter>
	// </HelmetProvider>
))
dvaInstance.start('#root')

export default dvaInstance
