/*
 * @Description: 数仓接口
 * @Author: tianxin
 * @Date: 2021-07-20 19:01:00
 * @LastEditors: Gao DeLong
 * @LastEditTime: 2021-08-27 19:33:53
 */

import { request } from '@/utils/request'
import type { RequestOptionsInit, RequestResponse } from 'umi-request'
interface dhRequestType {
	<T = any>(url: string, options: RequestOptionsInit): Promise<RequestResponse<T>>;
}

const dhRequest: dhRequestType = function (url: string, options: RequestOptionsInit) {
	return request(url, {
		prefix: '/dhapi',
		...options
	})
}

/**
 * 获取教育局安装覆盖
 */

 export async function getEducationInstall(params:APP_MANAGER_PARAMS.EducationInstallOption) {
  return dhRequest('/install/bureau', {
		method: 'GET',
		params,
	})
}
/**
 * 家庭养育环境 统计
 */

 export async function getClassStatistics(classId :string) {
  return request(`/data-analysis/app/class/eva/pi/statistics/${classId}`, {
		method: 'GET',
	})
}
/**
 * 区域_家长学习统计_周_折线数据
 */

 export async function getAreaSummaryList(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.SummaryList>('/bureau/study/week/summary/list', {
		method: 'GET',
		params,
	})
}
/**
 * 学校_家长学习统计_周_折线数据
 */

 export async function getSchoolSummaryList(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.SummaryList>('/school/study/week/summary/list', {
		method: 'GET',
		params,
	})
}
/**
 * 区域_热门_课程
 */
export async function getAreaCourseList(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.AreaCourseList>('/bureau/hot/course/list', {
		method: 'GET',
		params,
	})
}
/**
 * 安装覆盖_区域装机_各学校安装情况
 */

 export async function getInstallSchool(params:APP_MANAGER_PARAMS.InstallSchoolParams) {
  return dhRequest('/bureau/install/school/page', {
		method: 'GET',
		params,
	})
}

/**
 * 学校_热门_课程
 */
 export async function getSchoolCourseList(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.AreaCourseList>('/school/hot/course/list', {
		method: 'GET',
		params,
	})
}

/**
 * 获取学校安装覆盖
 */

 export async function getSchoolInstall(params:APP_MANAGER_PARAMS.SchoolInstallOption) {
  return dhRequest('/install/school', {
		method: 'GET',
		params,
	})
}
/*
 * 加入学校/教育局-超管信息
 */
 export async function getSuperAdminInfo(params:any) {
  return request('/platform/install/process/getSuperAdminInfo', {
    method: 'GET',
		// prefix:'',
		params,
  })
}
/**
 * 学校_热门_课程
 */
 export async function getTopicList(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.topicList>('/common/content/topic/list', {
		method: 'GET',
		params,
	})
}
/**
 * 新增学校教师
 */
 export async function saveTeacher(data:APP_MANAGER_PARAMS.saveTeacher) {
  return request('/platform/install/process/saveTeacher', {
		method: 'POST',
		// prefix:'',
		data,
    showTips: false
	})
}
/**
 * 新增教育局普通成员
 */
 export async function saveBureau(data:APP_MANAGER_PARAMS.saveBureau) {
  return request('/platform/install/process/saveBureau', {
		method: 'POST',
		// prefix:'',
		data,
    showTips: false
  })
}
/**
 * 学校_内容贡献排行榜
 */
 export async function getSchoolRanking(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.rankList>('/school/content/contribute/ranking/list', {
		method: 'GET',
		params,
	})
}
/**
 * 区域_内容贡献排行榜
 */
 export async function getAreaRanking(params:any) {
  return dhRequest<APP_MANAGER_PARAMS.rankList>('/bureau/content/contribute/ranking/list', {
		method: 'GET',
		params,
	})
}

/**
 * 安装覆盖_区域装机_各学校安装情况
 */

 export async function getInstallClass(params:APP_MANAGER_PARAMS.ClassInstallOption) {
  return dhRequest('/school/install/class/list', {
		method: 'GET',
		params,
	})
}

/**
 * 区域_家访完成情况_汇总
 */

 export async function getHomeVisitEducation(params:APP_MANAGER_PARAMS.EducationHomeVisitOption) {
  return dhRequest('/bureau/home/visit/summary', {
		method: 'GET',
		params,
	})
}

/**
 * 区域_家访问卷记录_选项统计
 */

 export async function getHomeVisitQuestionEducation(params:APP_MANAGER_PARAMS.EducationHomeVisitQesOption) {
  return dhRequest('/bureau/home/visit/question/summary', {
		method: 'GET',
		params,
	})
}

/**
 * 学校_家访完成情况_汇总
 */

 export async function getHomeVisitSchool(params:APP_MANAGER_PARAMS.SchoolHomeVisitOption) {
  return dhRequest('/school/home/visit/summary', {
		method: 'GET',
		params,
	})
}
/**
 * 学校_家访问卷记录_选项统计
 */

 export async function getHomeVisitQuestionSchool(params:APP_MANAGER_PARAMS.SchoolHomeVisitQesOption) {
  return dhRequest('/school/home/visit/question/summary', {
		method: 'GET',
		params,
	})
}

/**
 * 区域_家访完成情况_学校详情
 */

 export async function getHomeVisitDetailSchool(params:APP_MANAGER_PARAMS.HomeVisitSchoolParams) {
  return dhRequest('/bureau/home/visit/school/page', {
		method: 'GET',
		params,
	})
}

/**
 * 学校_家访完成情况_家访详情_班级
 */

 export async function getHomeVisitDetailClass(params:APP_MANAGER_PARAMS.HomeVisitClassParams) {
  return dhRequest('/school/home/visit/class/page', {
		method: 'GET',
		params,
	})
}