/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: xiaoxin
 * @LastEditTime: 2021-12-07 09:28:49
 */

import { getHomeVisitEducation, getHomeVisitQuestionEducation } from '@/services/api/AppManager/dhapi';


//学段信息
export interface SectionOpt  {
    //学段名称
    sectionName:string;
    //学段数字颜色
    numColor:string;
    //学段圆点颜色
    dotColor:string;
    //学段svg圆环渐变色
    svgColor:string[]
}
//格式化学段内全部数据
export interface EducationHomeVisitItemFormat extends APP_MANAGER_API.EducationHomeVisitItem,SectionOpt {
  //格式化后的安装率
  percent:number;
}

export interface EducationHomeVisitQesItemFormat extends APP_MANAGER_API.SchoolHomeVisitQesItem {
  //每个问卷调查下最大的项
  maxCount:number;
}

export type schoolHomeVisitState = {
    //教育局安装汇总
    educationHomeVisitTotal : APP_MANAGER_API.EducationHomeVisitItem;
    //各学段安装汇总
    educationHomeVisit: EducationHomeVisitItemFormat[];
    //问卷记录
    educationHomeVisitQes:EducationHomeVisitQesItemFormat[][];
};

interface ModelType {
  namespace: string;
  state: schoolHomeVisitState;
  effects: {
    getEducationHomeVisit: Effect;
    getHomeVisitQuestionEducation:Effect;
  };
  reducers: {
    setState: Reducer<schoolHomeVisitState>;
  };
}
function getsectionName(section:number) : SectionOpt{
  let sectionOpt:SectionOpt = {
    sectionName:'',
    numColor:'',
    dotColor:'',
    svgColor:[]
  };

  switch (section) {
    case 1:
      sectionOpt.sectionName = '幼儿'
      sectionOpt.numColor = 'kc'
      sectionOpt.dotColor = 'kb'
      sectionOpt.svgColor = ['colork1','colork2']
      break;
    case 2:
      sectionOpt.sectionName = '小学'
      sectionOpt.numColor = 'pc'
      sectionOpt.dotColor = 'pb'
      sectionOpt.svgColor = ['colorp1','colorp2']
      break;
    case 3:
      sectionOpt.sectionName = '初中'
      sectionOpt.numColor = 'mc'
      sectionOpt.dotColor = 'mb'
      sectionOpt.svgColor = ['colorm1','colorm2']
      break;
    case 4:
      sectionOpt.sectionName = '高中'
      sectionOpt.numColor = 'hc'
      sectionOpt.dotColor = 'hb'
      sectionOpt.svgColor = ['colorh1','colorh2']
      break;
    default:
      break;
  }
  return sectionOpt;
}
//分页数据
// let schoolData:APP_MANAGER_API.InstallSchoolItem[] = [];

function sortClass(sortData:APP_MANAGER_API.EducationHomeVisitQesItem[]){
  const groupBy = (array:APP_MANAGER_API.EducationHomeVisitQesItem[], f:any) => {
    let groups:any = {}
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  };
  const sorted = groupBy(sortData, (item:any) => {
    return item.title; // 返回需要分组的对象
  });
  return sorted;
};

const EducationHomeVisitModel: ModelType = {
  namespace: 'educationHomeVisitModel',
  state: {
    educationHomeVisitTotal:{
      count:   0,
      section: 0,
      ratio:   0
    },
    educationHomeVisit:[],
    educationHomeVisitQes:[[]]
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getEducationHomeVisit({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitEducation, payload);
          // eslint-disable-next-line array-callback-return
          let educationHomeVisitTotal = result.filter((item:APP_MANAGER_API.EducationHomeVisitItem,index:number) => {
            if(item.section === 0){
              return item 
            }
          });
          // eslint-disable-next-line array-callback-return
          let sectionInstall = result.filter((item:APP_MANAGER_API.EducationHomeVisitItem,index:number) => {
            if(item.section !== 0){
              return item 
            }
          });
          let sectionInstallFormat =  sectionInstall.map((item:APP_MANAGER_API.EducationHomeVisitItem,index:number) =>{
            return {
              ...item,
              percent:Math.round(item.ratio * 100),
              sectionName:getsectionName(item.section).sectionName,
              numColor:getsectionName(item.section).numColor,
              dotColor:getsectionName(item.section).dotColor,
              svgColor:getsectionName(item.section).svgColor
            }
          })
        yield put({
          type: 'setState',
          payload: {
            educationHomeVisitTotal: educationHomeVisitTotal[0],
            educationHomeVisit:sectionInstallFormat
          },
        });
      } catch (error) {}
    },
    *getHomeVisitQuestionEducation({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitQuestionEducation, payload); 
        //数组自分组
        let groupResult = sortClass(result);
        let formatGroupResult:EducationHomeVisitQesItemFormat[][] = [];
        //在子分组中的每组中找出最大值加到属性中（maxCount）
        for(let i of groupResult){
          let countArr = []
          for(let j of i){
            countArr.push(j.count)  
          }
          let maxCount = Math.max(...countArr)
          let newItem:EducationHomeVisitQesItemFormat = {
            maxCount:0,
            opt:'',
            count:0,
            title:''
          }
          let newArr:EducationHomeVisitQesItemFormat[] = []
          for(let j of i){
            j.maxCount = maxCount
            newItem = j
            newArr.push(newItem)
          }
          formatGroupResult.push(newArr)
        }
        // const result = yield [
        //   {
        //     "opt": "其他",
        //     "count": 22,
        //     "title": "兴趣爱好"
        //   },
        //   {
        //     "opt": "智力",
        //     "count": 12,
        //     "title": "兴趣爱好"
        //   },
        //   {
        //     "opt": "球类",
        //     "count": 233,
        //     "title": "兴趣爱好"
        //   },
        //   {
        //     "opt": "艺术",
        //     "count": 100,
        //     "title": "兴趣爱好"
        //   },
        //   {
        //     "opt": "一般",
        //     "count": 100,
        //     "title": "身体情况"
        //   },
        //   {
        //     "opt": "健康",
        //     "count": 233,
        //     "title": "身体情况"
        //   },
        //   {
        //     "opt": "差",
        //     "count": 22,
        //     "title": "身体情况"
        //   },
        //   {
        //     "opt": "较差",
        //     "count": 12,
        //     "title": "身体情况"
        //   }
        // ];
        
        yield put({
          type: 'setState',
          payload: {
            educationHomeVisitQes: formatGroupResult,
          },
        });
      }catch (error) {}
    }
  },
};

export default EducationHomeVisitModel;