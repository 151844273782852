/*
 * @Description:
 * @Author: xiaoxin
 * @Date: 2021-07-05 14:11:51
 * @LastEditors: zhangzhijie
 * @LastEditTime: 2021-08-16 15:56:20
 */

// ******************************************************
// 注意： 此文件的执行环境是 .gene 或 .gene-production 目录
// ******************************************************

import { useEffect } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
// @ts-ignore
import Routers from './router'

function App() {
	// @ts-ignore
	const redirectRouter = Routers.find(item => item.redirect)
	return (
		<Switch>
			{/* @ts-ignore */}
			{Routers.map((router) => (
				<Route exact={!router.notExect} key={router.path} path={router.path} component={router.component}></Route>
			))}
			{/* 设置默认路由 推荐方法一*/}
			{/* 方法一 */}
			{/* <Route path="/" component={Home} exact></Route> */}
			{/* 方法二 重定向*/}
			<Redirect path='/' to={redirectRouter ? redirectRouter.path : '/404'} />
		</Switch>
	)
}

export default withRouter(App)
