/*
 * @Description: 个人家访报告
 * @Author: heyat
 * @Date: 2021-07-20 11:44:13
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2022-01-18 14:49:27
 */
import { getPersonalInfo } from "@/services/api/AppManager/calendar-app";
export type PersonalReportState = {
  PersonalInfo: APP_MANAGER_PARAMS.PersonalInfo;
};

interface PersonalReportModelType {
  namespace: string;
  state: PersonalReportState;
  effects: {
    getInfo: Effect;
  };
  reducers: {
    setState: Reducer<PersonalReportState>;
  };
}
const PersonalReportsModel: PersonalReportModelType = {
  namespace: "personalReport",
  state: {
    PersonalInfo: {
      atime: "",
      analyses: [],
      recordDetails: [
        {
          firstTagName: "",
          questionDetails: [
            {
              optionContent: "",
              questionName: "",
              sort: 0,
              tagName: "",
            },
          ],
        },
      ],
      studentName: "",
      feedback: "",
      summary: ""
    },
  },
  effects: {
    /**
     * @description: 获取家访报告
     * @param {*}
     * @return {*}
     */
    *getInfo({ param, callback }, { call, put }) {
      try {
        const value = yield call(getPersonalInfo, param);
        yield put({
          type: "setState",
          payload: {
            PersonalInfo: value,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  reducers: {
    // 设置简单通用数据
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default PersonalReportsModel;
