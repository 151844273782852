/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: tianxin
 * @LastEditTime: 2021-08-04 17:30:24
 */

import { getSchoolInstall, getInstallClass } from '@/services/api/AppManager/dhapi';




export type classParam = {
  label:string,
  value:string,
}
export type schoolInstallState = {
    //学校安装汇总
    schoolInstallTotal : APP_MANAGER_API.schoolInstall;
    //班级安装
    installClass:APP_MANAGER_API.InstallClassItem[];
    //班级筛选
    classOptions:classParam[];
};


interface ModelType {
  namespace: string;
  state: schoolInstallState;
  effects: {
    getSchoolInstall: Effect;
    getInstallClass:Effect;
  };
  reducers: {
    setState: Reducer<schoolInstallState>;
  };
}
//分页数据
// let classInstallData:APP_MANAGER_API.InstallSchoolItem[] = [];

const SchoolInstallModel: ModelType = {
  namespace: 'schoolInstallModel',
  state: {
    schoolInstallTotal:{
      installClassCount: 0,
      studentCount: 0,
      parentCount: 0,
    },
    installClass:[],
    classOptions:[]
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getSchoolInstall({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getSchoolInstall, payload);
        yield put({
          type: 'setState',
          payload: {
            schoolInstallTotal: result.length > 0 ? result[0] : {
              installClassCount: 0,
              studentCount: 0,
              parentCount: 0,
            },
          },
        });
      } catch (error) {}
    },
    *getInstallClass({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getInstallClass, payload);
        let classOptions = result.map((item:APP_MANAGER_API.InstallClassItem,index:number) =>{
          return {
            label:item.name,
            value:item.id.toString()
          }
        })
        classOptions = [{label:'全部班级',value:''},...classOptions]
        yield put({
          type: 'setState',
          payload: {
            installClass:result,
            classOptions:classOptions
          },
        });
        callback && callback(result)
        
      } catch (error) {}
    }
  },
};

export default SchoolInstallModel;