/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-07-06 13:39:02
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-07-06 13:47:27
 */
const checkENV = () => ['development'].includes(process.env.NODE_ENV as string);

/**
 * @description: 设置cookie，传入name和value设置
 * @name: 设置cookie
 * @param {String} name
 * @param {String} value
 */
export const setCookie = (name: string, value: string) => {
  if (!name || !value) return;
  if (checkENV()) {
    document.cookie = `${name}=${value};path=/;`;
  } else {
    document.cookie = `${name}=${value};path=/;domain=.wenxiangcn.com`;
  }
};

/**
 * @description: 默认获取所有cookie，传入name只返回对应cookie
 * @name: 获取cookie
 * @param {String} name
 * @return {Array|String}
 */
type resultProps = {
  [key: string]: any;
};
export const getCookie = (name?: string) => {
  let result = null;
  if (!name) {
    result = {} as resultProps;
  }
  const cookieList = document.cookie.split('; ');
  for (let i = 0, cookieLenth = cookieList.length; i < cookieLenth; i += 1) {
    const inx = cookieList[i].indexOf('=');
    const cookieKey = cookieList[i].slice(0, inx);
    const cookieValue = cookieList[i].slice(inx + 1);
    if (name) {
      if (name === cookieKey) {
        result = cookieValue;
        break;
      } else {
        result = null;
      }
    } else if (result) {
      result[cookieKey] = cookieValue;
    }
  }
  return result;
};

/**
 * @description: 删除cookie
 */
export const removeCookie = (name: string) => {
  const d = new Date();
  d.setTime(d.getTime() - 86400);
  if (checkENV()) {
    document.cookie = `${name}=12;expires=${d.toUTCString()};path=/;`;
  } else {
    document.cookie = `${name}=12;expires=${d.toUTCString()};path=/;domain=.wenxiangcn.com`;
  }
};

/**
 * @description: 删除所有Cookie
 */
export const removeAllCookie = () => {
  const allCookieKeys = Object.keys(getCookie() || {});
  allCookieKeys.forEach((item) => {
    removeCookie(item);
  });
};

/**
 * @description: 枚举返回本身处理
 * @param {Record} enumClass
 * @param {string｜number} key
 * @param {string} baseUrl
 * @return {string｜number} key
 */
 export const enumGetKey = (
  enumClass: Record<string, string | number>,
  key: string,
  baseUrl?: string,
): string => (baseUrl || '') + enumClass[enumClass[key]];