/*
 * @Description: calendar-app服务接口
 * @Author: tianxin
 * @Date: 2021-07-14 10:35:14
 * @LastEditors: xiaoxin
 * @LastEditTime: 2021-12-07 09:58:42
 */

// import { enumGetKey } from '@/utils'
import { request } from '@/utils/request'

const baseUrl = '/calendar-app'
// enum ApiCalendarApp {
// 	'/parent/topic/',
// }
/**
 * 获取安卓下载路径
 */
 export async function getAndroidUrl(type: any,channelCode:any) {
	return request(`${baseUrl}/app/renewal/channel/${type}/${channelCode}`,{
		method: 'GET',
	})
}
/**
 * 获取主题详情列表（测评+活动+文章）
 */
export async function themeDetailList(id: string,params:{userId: string}) {
	return request(`${baseUrl}/parent/topic/${id}`, {
		method: 'GET',
		params
	})
}

/**
 * 获取家访调查详情
 */
export async function getInvestigationDetail(params: APP_PARAMS.GetInvestigationDetail) {
	return request(`${baseUrl}/home/visit/survey/detail`, {
		method: 'GET',
		params,
	})
}

/**
 * 获取PI测评id
 */
export async function getPIEvaluationId() {
	return request(`${baseUrl}/calender/eva/status`, {
		method: 'GET',
	})
}

/**
 * 获取修改后的PI测评（家庭报告）结果
 */
 export async function getUpdatedPIEvaluationResult(params: APP_PARAMS.GetUpdatedPIEvaluationResult) {
	return request(`${baseUrl}/home/visit/survey/detail/eva/data`, {
		method: 'GET',
		params,
	})
}

/**
 * 提交家访调查问卷
 */
 export async function submitInvestigation(params: APP_PARAMS.SubmitInvestigation) {
	return request(`${baseUrl}/home/visit/survey/submit`, {
		method: 'POST',
		data: params,
	})
}

/**
 * 获取通知详情
 */
export async function getNotificationDetail(params: APP_PARAMS.GetNotificationDetail) {
	return request(`${baseUrl}/notice/family/info`, {
		method: 'POST',
		data: params,
	})
}

/**
* 获取内容详情
*/
export async function getParentContent(id: string) {
 return request<APP_API.ActivityDetail>(`${baseUrl}/parent/content/${id}`, {
	 method: 'get',
 })
}

/**
* 获取内容详情
*/
export async function getParentUserRecordList(id: string, params: object) {
	return request<APP_API.ActivityDetail>(`${baseUrl}/parent/user/record/list/content/${id}`, {
		method: 'get',
		params,
	})
 }

