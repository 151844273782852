/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: xiaoxin
 * @LastEditTime: 2021-12-07 09:29:40
 */

import { getEducationInstall, getInstallSchool } from '@/services/api/AppManager/dhapi';


//学段信息
export interface SectionOpt  {
    //学段名称
    sectionName:string;
    //学段数字颜色
    numColor:string;
    //学段圆点颜色
    dotColor:string;
    //学段svg圆环渐变色
    svgColor:string[]
}
//格式化学段内全部数据
export interface EducationInstallItemFormat extends APP_MANAGER_API.EducationInstallItem,SectionOpt {
  //格式化后的安装率
  percent:number;
}

export type educationInstallState = {
    //教育局安装汇总
    educationInstallTotal : APP_MANAGER_API.EducationInstallItem;
    //各学段安装汇总
    sectionInstall: EducationInstallItemFormat[];
    //学校安装数量
    installSchool:APP_MANAGER_API.InstallSchoolItem[];
    //学校安装数据总条数
    totalNum:number
};

interface ModelType {
  namespace: string;
  state: educationInstallState;
  effects: {
    getEducationInstall: Effect;
    getInstallSchool:Effect;
  };
  reducers: {
    setState: Reducer<educationInstallState>;
  };
}
function getsectionName(section:number) : SectionOpt{
  let sectionOpt:SectionOpt = {
    sectionName:'',
    numColor:'',
    dotColor:'',
    svgColor:[]
  };

  switch (section) {
    case 1:
      sectionOpt.sectionName = '幼儿'
      sectionOpt.numColor = 'kc'
      sectionOpt.dotColor = 'kb'
      sectionOpt.svgColor = ['colork1','colork2']
      break;
    case 2:
      sectionOpt.sectionName = '小学'
      sectionOpt.numColor = 'pc'
      sectionOpt.dotColor = 'pb'
      sectionOpt.svgColor = ['colorp1','colorp2']
      break;
    case 3:
      sectionOpt.sectionName = '初中'
      sectionOpt.numColor = 'mc'
      sectionOpt.dotColor = 'mb'
      sectionOpt.svgColor = ['colorm1','colorm2']
      break;
    case 4:
      sectionOpt.sectionName = '高中'
      sectionOpt.numColor = 'hc'
      sectionOpt.dotColor = 'hb'
      sectionOpt.svgColor = ['colorh1','colorh2']
      break;
    default:
      break;
  }
  return sectionOpt;
}
//分页数据
let schoolData:APP_MANAGER_API.InstallSchoolItem[] = [];

const EducationInstallModel: ModelType = {
  namespace: 'educationInstallModel',
  state: {
    educationInstallTotal:{
      installRatio:        0,
      studentInstallCount: 0,
      bureauId:            0,
      schoolInstallCount:  0,
      schoolTotalCount:    0,
      section:             0,
      bureauName:          '',
      parentInstallCount:  0,
    },
    sectionInstall:[],
    installSchool:[],
    totalNum:0
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getEducationInstall({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getEducationInstall, payload);
          // eslint-disable-next-line array-callback-return
          let educationInstallTotal = result.filter((item:APP_MANAGER_API.EducationInstallItem,index:number) => {
            if(item.section === 0){
              return item 
            }
          });
          // eslint-disable-next-line array-callback-return
          let sectionInstall = result.filter((item:APP_MANAGER_API.EducationInstallItem,index:number) => {
            if(item.section !== 0){
              return item 
            }
          });
          let sectionInstallFormat =  sectionInstall.map((item:APP_MANAGER_API.EducationInstallItem,index:number) =>{
            return {
              ...item,
              percent:Math.round(item.installRatio * 100),
              sectionName:getsectionName(item.section).sectionName,
              numColor:getsectionName(item.section).numColor,
              dotColor:getsectionName(item.section).dotColor,
              svgColor:getsectionName(item.section).svgColor
            }
          })
        yield put({
          type: 'setState',
          payload: {
            educationInstallTotal: educationInstallTotal[0],
            sectionInstall:sectionInstallFormat
          },
        });
      } catch (error) {}
    },
    *getInstallSchool({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getInstallSchool, payload);
         if(payload.pageNum === 1){
          schoolData = result.rows
         }else{
          schoolData = schoolData.concat(result.rows)
         }
         console.log(payload.pageNum)
         console.log('result',result)
         console.log('schoolData',schoolData)
        yield put({
          type: 'setState',
          payload: {
            installSchool:schoolData,
            totalNum:result.totalNum ? result.totalNum : 0
          },
        });
        callback && callback()
        
      } catch (error) {}
    }
  },
};

export default EducationInstallModel;