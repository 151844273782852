/*
 * @Description:
 * @Author: 你的名字
 * @Date: 2021-07-24 15:19:57
 * @LastEditors: jiaojinhui
 * @LastEditTime: 2022-01-18 17:28:37
 */
import { request } from '@/utils/request'

/** 获取七牛token  */
export async function qiNiuToken(options?: Record<string, any>) {
	return request<Record<string, any>>('/res/qiniu/upload/token', {
		method: 'get',
		params: options,
	})
}