/*
 * @Description: 家庭环境model
 * @Author: Gao DeLong
 * @Date: 2021-07-19 11:15:54
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-08-25 16:23:18
 */

import {  getClassStatistics } from '@/services/api/AppManager/dhapi'

export type FamilyReportState = {
	// 学段环境数据
	contentList: APP_MANAGER_API.FamilyReportItem[]
	//空数据
	isEmpty: boolean
}

interface FamilyReportModelType {
	namespace: string
	state: FamilyReportState
	effects: {
		changeState: Effect
		getSummary: Effect //家庭环境
	}
	reducers: {
		setState: Reducer
	}
}

type pageDataType = {
	contentList: Array<APP_MANAGER_API.FamilyReportItem[]>; //数据
	date: string; //日期
	parentCount: number //家长人数
}

/**
 * @description: 学校数据处理
 * @param {any} result:页面数据
 * @return {*}
 */
const setPageData = (result: any) => {
	// console.log(result.length,'result');
	// 页面数据
	let PageData: pageDataType;
	let dataList: APP_MANAGER_API.FamilyReportItem[] = [] //家庭环境适应性评估数据
	let parentList: APP_MANAGER_API.FamilyReportItem[] = [] //家长指数数据
	let threeList: APP_MANAGER_API.FamilyReportItem[] = [] //家庭三维度数据
  let date:string = ''  //日期
  let parentCount: number = 0  //家长人数
	// 整体数据处理
	result.forEach((item: APP_MANAGER_API.FamilyReportItem) => {
		if (item.gaugeNumber === 'family-001') {
			dataList.push(item)
      date = item.date
      parentCount = item.parentCount
		}
    else if(item.gaugeNumber === 'family-001-D' || item.gaugeNumber === 'family-001-E' ) {
			parentList.push(item)
		}else{
      threeList.push(item)
    }
	})


	PageData = {
		contentList: [dataList,parentList,threeList],
    date: date, //日期
    parentCount: parentCount //家长人数
	}
	return PageData
}
const FamilyReportModel: FamilyReportModelType = {
	namespace: 'familyReport',
	state: {
		contentList: [],
		isEmpty: false,
	},

	effects: {
		/**
		 * @description: 获取家庭环境数据
		 * @param {*}
		 * @return {*}
		 */
		*getSummary({ payload }, { call, put }) {
			try {
				let result
				let PageData
				result = yield call(getClassStatistics, payload.classId) //学校数据
				// 空数据判断
				if (result.length > 0) {
					yield put({
						type: 'setState',
						payload: {
							isEmpty: false,
						},
					})
				} else {
					yield put({
						type: 'setState',
						payload: {
							isEmpty: true,
						},
					})
				}
				PageData = setPageData(result)
				yield put({
					type: 'setState',
					payload: {
						contentList: PageData.contentList, //班级数据
            date: PageData.date, //日期
            parentCount: PageData.parentCount //家长人数
					},
				})
			} catch (error) {}
		},
		*changeState({ payload }, { put }) {
			yield put({
				type: 'setState',
				payload: payload,
			})
		},
	},
	reducers: {
		setState(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
}

export default FamilyReportModel
