/*
 * @Description: 班级报告
 * @Author: heyat
 * @Date: 2021-07-20 14:50:06
 * @LastEditors: heyat
 * @LastEditTime: 2021-08-20 16:52:46
 */
import { getClassInfo } from "@/services/api/AppManager/calendar-app";

export type ClassReportState = {
  ClassInfo: APP_MANAGER_PARAMS.ClassInfo;
};

interface ClassReportModelType {
  namespace: string;
  state: ClassReportState;
  effects: {
    getClassInfo: Effect;
  };
  reducers: {
    setState: Reducer<ClassReportState>;
  };
}
const ClassReportsModel: ClassReportModelType = {
  namespace: "classReport",
  state: {
    ClassInfo: [{
      details:[
        {
          homeVisitId:0,
          id:0,
          questionId:0,
          resultJson:'',
          showTitle:'',
          sort:0,
          tagId:0,
          tagName:'',
        }
      ],
      sort: 0,
      tagId:'',
      tagName:''
    }],
  },
  effects: {
    /**
     * @description: 获取班级报告
     * @param {*}
     * @return {*}
     */    
    *getClassInfo({ param }, { call, put }) {
      try {
        const value =yield call( getClassInfo ,param)
        yield put({
          type: "setState",
          payload: {
            ClassInfo: value,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  reducers: {
    // 设置简单通用数据
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default ClassReportsModel;
