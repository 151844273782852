// @ts-nocheck
import { DvaInstance } from 'dva'
import Modelcommon0 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/models/common.ts';
import ModelmobileCommon1 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/models/mobileCommon.ts';
import ModeleducationHomeVisitModel2 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/EducationHome/HomeVisit/models/educationHomeVisit.ts';
import ModeleducationHomeVisitDetailModel3 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/EducationHome/HomeVisit/models/educationHomeVisitDetail.ts';
import ModeleducationInstallModel4 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/EducationHome/InstallDetail/models/educationInstall.ts';
import ModelactivityAdmin5 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/FamilyDetail/models/index.ts';
import Modelinvite6 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Invite/models/Invite.ts';
import ModelclassReport7 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Report/ClassReport/models/index.ts';
import ModelcooperateReport8 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Report/CooperateReport/models/index.ts';
import ModelfamilyReport9 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Report/FamilyReport/models/index.ts';
import ModelparentsStudy10 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Report/ParentsStudy/models/index.ts';
import ModelpersonalReport11 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/Report/PersonalReport/models/index.ts';
import ModelschoolHomeVisitModel12 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/SchoolHome/HomeVisit/models/schoolHomeVisit.ts';
import ModelschoolHomeVisitDetailModel13 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/SchoolHome/HomeVisit/models/schoolHomeVisitDetail.ts';
import ModelschoolInstallModel14 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/SchoolHome/InstallDetail/models/schoolInstall.ts';
import ModelthemeDetailListModelG15 from '/var/lib/jenkins/workspace/client-w3/wx_common_h5_appmh5/src/page/AppManager/TeacherHome/ThemeIntroduction/models/themeIntrodution.ts'
const models = [Modelcommon0,ModelmobileCommon1,ModeleducationHomeVisitModel2,ModeleducationHomeVisitDetailModel3,ModeleducationInstallModel4,ModelactivityAdmin5,Modelinvite6,ModelclassReport7,ModelcooperateReport8,ModelfamilyReport9,ModelparentsStudy10,ModelpersonalReport11,ModelschoolHomeVisitModel12,ModelschoolHomeVisitDetailModel13,ModelschoolInstallModel14,ModelthemeDetailListModelG15]

const InitModel = (app: DvaInstance) => {
  models.forEach((item) => {
    app.model(item)
  })
}

export default InitModel
