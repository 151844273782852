/*
 * @Description:
 * @Author: Gao DeLong
 * @Date: 2021-08-23 14:57:18
 * @LastEditors: Gao DeLong
 * @LastEditTime: 2021-08-30 21:26:20
 */
/*
 * @Description:  学校端model
 * @Author: Gao DeLong
 * @Date: 2021-07-06 15:13:25
 * @LastEditors: Gao DeLong
 * @LastEditTime: 2021-07-26 15:19:02
 */
import { getAndroidUrl } from '@/services/api/AppFamily/calendar-app'
import { sendMobileCode } from '@/services/api/AppFamily/platform-install'

export type SchoolModelState = {}

interface SchoolModelType {
	namespace: string
	state: SchoolModelState
	effects: {
		sendMobileCode: Effect
		getAndroidUrl: Effect
	}
	reducers: {
		setState: Reducer
	}
}

const SchoolModel: SchoolModelType = {
	namespace: 'mobileCommon',
	state: {},
	effects: {
		/**
		 * @description: 学校状态判断
		 * @param {*}
		 * @return {*}
		 */
		*sendMobileCode({ payload }, { call, put }) {
			try {
				const result = yield call(sendMobileCode, payload.mobile)
				return result
			} catch (error) {}
		},
		/**
		 * @description: 下载Android
		 * @param {*}
		 * @return {*}
		 */
		*getAndroidUrl({ payload }, { call, put }) {
			try {
				const result = yield call(getAndroidUrl, payload.type,payload.channelCode)
				return result
			} catch (error) {}
		},
	},
	reducers: {
		setState(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
}

export default SchoolModel
