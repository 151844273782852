/*
 * @Description: cms-user-data接口
 * @Author: tianxin
 * @Date: 2021-07-15 14:47:48
 * @LastEditors: jiaojinhui
 * @LastEditTime: 2021-11-25 19:52:30
 */

import { request } from '@/utils/request'

const baseUrl = '/platform/install'

/**
 * 判断二维码是否已经过期
 */
export async function checkQRCode(codeId: string) {
	return request<boolean>(`${baseUrl}/process/checkQRCode/${codeId}`, {
		method: 'POST',
	})
}
/**
 * 根据教育局id获取学校列表
 */
export async function getSchoolList(params: { bureauId: string; schoolName: string }) {
	return request<APP_API.schoolListItem[]>(
		`${baseUrl}/process/getSchoolList/${params['bureauId']}/${params['schoolName']}`,
		{
			method: 'GET',
		}
	)
}
/**
 * 学校状态判断
 */
export async function getSchoolJudge(schoolId: string) {
	return request<APP_API.schoolJudge[]>(`${baseUrl}/process/school/judge/${schoolId}`, {
		method: 'GET',
	})
}
/**
 * 发送手机验证码
 */
export async function sendMobileCode(mobile: string) {
	return request(`${baseUrl}/process/sendMobileCode`, {
		method: 'POST',
		data: {
			mobile,
		},
		showTips: false,
	})
}

/**
 * 学校超管绑定手机号
 */
export async function schoolBindMobile(data: APP_PARAMS.schoolBindMobile) {
	return request(`${baseUrl}/process/schoolBindMobile`, {
		method: 'POST',
		data,
    showTips: false,
	})
}
/**
 * 完善年级班级
 */
export async function completeClass(data: APP_PARAMS.completeClass) {
	return request<APP_API.classList>(`${baseUrl}/process/completeClass`, {
		method: 'POST',
		data,
    showTips: false,
	})
}
/**
 * 获取学校信息
 */
export async function schoolInfo(schoolId: string) {
	return request<APP_API.schoolInfo>(`${baseUrl}/process/school/info/${schoolId}`, {
		method: 'GET',
	})
}
/**
 * 注册班主任
 */
export async function registerTeacher(data: APP_PARAMS.registerTeacherParams) {
	return request<APP_API.registerTeacher>(`${baseUrl}/process/register/teacher`, {
		method: 'POST',
    data,
    showTips: false
	})
}
/**
 * 获取学校的年级班级
 */
 export async function getClasses(schoolId: string) {
	return request<APP_API.getClasses>(`${baseUrl}/process/getClasses/${schoolId}`, {
		method: 'GET',
	})
}
/**
 * 班主任绑定班级
 */
 export async function teacherBindClass(data: APP_PARAMS.teacherBindClassParams) {
	return request(`${baseUrl}/process/teacherBindClass`, {
		method: 'POST',
    data,
    showTips: false
	})
}
/**
 * 家长注册
 */
 export async function saveParentJoinClass(data: APP_PARAMS.parentJoinClassParams) {
	return request(`${baseUrl}/process/newUser`, {
		method: 'POST',
    data,
    showTips: false
	})
}
/**
 * 家长注册 名字校验
 */
 export async function checkStudentName(params: APP_PARAMS.checkStudentNameParams) {
	return request(`${baseUrl}/process/check/student/name`, {
		method: 'GET',
    params,
    showTips: false
	})
}
/**
 * 家长注册-班主任信息
 */
 export async function getHeadTeacher(classId: string,userId: string) {
	return request(`${baseUrl}/process/getHeadTeacher/${classId}/${userId}`, {
		method: 'GET',
	})
}
/**
 * 家长注册-孩子列表
 */
 export async function getExistStudents(classId: string,userId: string) {
	return request(`${baseUrl}/process/list/exist/student/${classId}/${userId}`, {
		method: 'GET',
	})
}
/**
 * 家长注册-孩子转班
 */
 export async function changeStudentClass(data: Record<string, any>) {
	return request(`${baseUrl}/process/changeStudentClass`, {
		method: 'POST',
		data
	})
}

/**
 * 家长注册-校验/注册手机号
 */
 export async function checkParentPhone(params: Record<string, any>) {
	return request(`${baseUrl}/process/h5/parent/check/phone/number`, {
		method: 'GET',
		params
	})
}

/**
 * 家长注册-校验学生（h5端）
 */
 export async function checkStudentH5(params: Record<string, any>) {
	return request(`${baseUrl}/process/h5/parent/register/check`, {
		method: 'GET',
		params
	})
}

/**
 * 家长注册-绑定匹配学生（h5端）
 */
 export async function bindMatchedStudentH5(data: Record<string, any>) {
	return request(`${baseUrl}/process/h5/parent/register`, {
		method: 'POST',
		data
	})
}

/**
 * 家长注册-校验学生（app端）
 */
 export async function checkStudentAPP(params: Record<string, any>) {
	return request(`${baseUrl}/process/app/parent/register/check`, {
		method: 'GET',
		params
	})
}

/**
 * 家长注册-绑定匹配学生（app端）
 */
 export async function bindMatchedStudentAPP(data: Record<string, any>) {
	return request(`${baseUrl}/process/app/parent/register`, {
		method: 'POST',
		data
	})
}