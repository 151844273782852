/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: tianxin
 * @LastEditTime: 2021-07-28 17:23:58
 */

import { getHomeVisitDetailSchool } from '@/services/api/AppManager/dhapi';


export type educatioHomeVisitDetailState = {
    //教育局-学校家访详情
    homeVisitDetailSchool:APP_MANAGER_API.SchoolHomeVisitDetailItem[];
    //教育局-学校家访详情总条数
    totalNum:number
};

interface ModelType {
  namespace: string;
  state: educatioHomeVisitDetailState;
  effects: {
    getHomeVisitDetailSchool:Effect;
  };
  reducers: {
    setState: Reducer<educatioHomeVisitDetailState>;
  };
}
//分页数据
let schoolData:APP_MANAGER_API.SchoolHomeVisitDetailItem[] = [];

const EducationHomeVisitDetailModel: ModelType = {
  namespace: 'educationHomeVisitDetailModel',
  state: {
    homeVisitDetailSchool:[],
    totalNum:0
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getHomeVisitDetailSchool({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitDetailSchool, payload);
         if(payload.pageNum === 1){
          schoolData = result.rows
         }else{
          schoolData = schoolData.concat(result.rows)
         }
         console.log(payload.pageNum)
         console.log('result',result)
         console.log('schoolData',schoolData)
        yield put({
          type: 'setState',
          payload: {
            homeVisitDetailSchool:schoolData,
            totalNum:result.totalNum ? result.totalNum : 0
          },
        });
        callback && callback()
        
      } catch (error) {}
    }
  },
};

export default EducationHomeVisitDetailModel;