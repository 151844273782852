/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-07-17 14:30:55
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-08-03 19:17:00
 */

import { getAdminContent } from "@/services/api/AppManager/calendar-app";

export type ActivityModelState = {
  detail: Partial<APP_API.ActivityDetail>;
}

interface ActivityModelType {
  namespace: string;
  state: ActivityModelState;
  effects: {
    getDetail: Effect;
  },
  reducers: {
    setState: Reducer<ActivityModelState>
  }
}

const ActivityModel: ActivityModelType = {
  namespace: 'activityAdmin',
  state: {
    detail: {},
  },
  effects: {
    *getDetail({id, callback}, {call, put}) {
      try {
        const result = yield call(getAdminContent, id)
        yield put({
          type: 'setState',
          payload: {
            detail: result
          }
        })
      } catch(error) {
        console.log(error);
      }
    },
  },
  reducers: {
    setState(state, { payload }) {
      return {
        ...state,
        ...payload
      }
    }
  },
}

export default ActivityModel