/*
 * @Description: 家长学习
 * @Author: heyat
 * @Date: 2021-07-22 10:05:10
 * @LastEditors: heyat
 * @LastEditTime: 2021-07-23 16:55:55
 */
import {
  getAreaSummaryList,
  getSchoolSummaryList,
  getAreaCourseList,
  getSchoolCourseList
} from "@/services/api/AppManager/dhapi";
/**
 * @description: 图表数据过滤
 * @param {*} data
 * @param {string} typeName
 * @return {*}
 */
const deelData =( data:[],typeName:string)=>{
  if(data.length>0){
    let tempArr: any[] = [];
    let dataArr: any[] = []
    var newArr: { name: any; data: any[]; type: string; stack: string; }[] | { data: any[]; }[] = [];
    data.forEach((item:any,index:number)=>{
      let tagName= typeName==='area'?item.sectionName:item.gradeName

      if (tempArr.indexOf(tagName) === -1) {
        　　　　　newArr.push({
                  name: tagName,
                  data: [item.count],
                  type: 'line',
                  stack: '总量',
        　　　　});
        　　　　tempArr.push(tagName);
        　　} else {
        　　　　newArr[tempArr.indexOf(tagName)].data.push(item.count)
        　　}
        if(dataArr.indexOf(item.showDate) === -1){
          dataArr.push(item.showDate)
        }
    })
    const result ={
      series:newArr,
      data:dataArr
    }
    return result
  }
}
/**
 * @description: 热门课程数据过滤
 * @param {*}
 * @return {*}
 */
const dealCoruseData= (data:[])=>{
  if(data.length>0){
    let tempArr: any[] = [];
    var newArr:any[] = []
    data.forEach((item:any,index:number)=>{
      if (tempArr.indexOf(item.sectionName) === -1) {
        newArr.push({
          sectionName:item.sectionName,
          gradeList:[
            {
              gradeName:item.gradeName,
              title:item.title,
              count:item.count
            }
          ],
        })
        tempArr.push(item.sectionName)
      }else{
        newArr[tempArr.indexOf(item.sectionName)].gradeList.push(
          {
            gradeName:item.gradeName,
            title:item.title,
            count:item.count
          }
        )
      }
    })
    return newArr
  }
}
export type ParentsStudyState = {
  SummaryListClass: APP_MANAGER_PARAMS.chartList,
  SummaryListActive:  APP_MANAGER_PARAMS.chartList,
  chartAll:APP_MANAGER_PARAMS.SummaryList,
  couseList: APP_MANAGER_PARAMS.courAll
};
interface ParentsStudyModelType {
  namespace: string;
  state: ParentsStudyState;
  effects: {
    getSummaryList: Effect;
    getCourse: Effect;
  };
  reducers: {
    setState: Reducer<ParentsStudyState>;
  };
}
const ParentsStudyModel: ParentsStudyModelType = {
  namespace: "parentsStudy",
  state: {
    SummaryListClass: {
      series:[{
        name:'',
        data:[],
        type:'',
        stack:''
      }],
      data:['']
    },
    SummaryListActive:  {
      series:[{
        name:'',
        data:[],
        type:'',
        stack:''
      }],
      data:['']
    },
    chartAll:[],
    couseList:[]
  },
  effects: {
    /**
     * @description: 学习统计_周_折线数据
     * @param {*}
     * @return {*}
     */
    *getSummaryList({ param1, typeNames }, { call, put }) {
      try {
        let value =[]
        if (typeNames === "area") {
          value = yield call(getAreaSummaryList, {bureauId:param1.id}); // 区域
        } else if (typeNames === "school") {
          value = yield call(getSchoolSummaryList,{schoolId:param1.id}); // 学校
        }
        let classList:any = []
        let activeList:any  = []
        let chartAllList: { type: number; sectionName:string;gradeName:string}[] = []
        value.forEach((item: { type: number; sectionName:string;gradeName:string}) => {
          let tagName= typeNames==='area'?item.sectionName:item.gradeName
          if(item.type===2 && tagName!==''){
            classList.push(item)
          }else if(item.type===3 && tagName!==''){
            activeList.push(item)
          }else{
            chartAllList.push(item)
          } 
        });
       const classChartInfo = deelData(classList,typeNames)
       const activeChartInfo = deelData(activeList,typeNames)
       
        yield put({
          type: "setState",
          payload: {
            SummaryListClass: classChartInfo,
            SummaryListActive: activeChartInfo,
            chartAll:chartAllList
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    *getCourse({ param2, typeNames }, { call, put }){
      try {
        let value =[]
        if (typeNames === "area") {
          value = yield call(getAreaCourseList, {bureauId:param2.id}); // 区域
        } else if (typeNames === "school") {
          value = yield call(getSchoolCourseList,  {bureauId:param2.schoolid, section:param2.section}); // 学校
        }
       const course = dealCoruseData(value)

       yield put({
        type: "setState",
        payload: {
          couseList:course
        },
      });
      }catch (error) {
        console.log(error);
      }
    }
  },
  reducers: {
    // 设置简单通用数据
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default ParentsStudyModel;
