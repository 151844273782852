/*
 * @Description: 
 * @Author: tianxin
 * @Date: 2021-07-20 19:13:31
 * @LastEditors: tianxin
 * @LastEditTime: 2021-07-28 14:05:19
 */

import { getHomeVisitSchool, getHomeVisitQuestionSchool } from '@/services/api/AppManager/dhapi';



export interface SchoolHomeVisitQesItemFormat extends APP_MANAGER_API.SchoolHomeVisitQesItem {
  //每个问卷调查下最大的项
  maxCount:number;
}


export type schoolHomeVisitState = {
    //教育局安装汇总
    schoolHomeVisitTotal : APP_MANAGER_API.SchoolHomeVisitItem;
    //问卷记录
    schoolHomeVisitQes:SchoolHomeVisitQesItemFormat[][];
};

interface ModelType {
  namespace: string;
  state: schoolHomeVisitState;
  effects: {
    getSchoolHomeVisit: Effect;
    getHomeVisitQuestionSchool:Effect;
  };
  reducers: {
    setState: Reducer<schoolHomeVisitState>;
  };
}

function sortClass(sortData:APP_MANAGER_API.SchoolHomeVisitQesItem[]){
  const groupBy = (array:APP_MANAGER_API.SchoolHomeVisitQesItem[], f:any) => {
    let groups:any = {}
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  };
  const sorted = groupBy(sortData, (item:any) => {
    return item.title; // 返回需要分组的对象
  });
  return sorted;
};

const SchoolHomeVisitModel: ModelType = {
  namespace: 'schoolHomeVisitModel',
  state: {
    schoolHomeVisitTotal:{
      count:   0,
      section: 0,
      ratio:   0
    },
    schoolHomeVisitQes:[[]]
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getSchoolHomeVisit({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitSchool, payload);
        let schoolHomeVisitTotal = result[0]
        yield put({
          type: 'setState',
          payload: {
            schoolHomeVisitTotal: schoolHomeVisitTotal,
          },
        });
      } catch (error) {}
    },
    *getHomeVisitQuestionSchool({ payload, callback }, { call, put }) {
      try {
        const result = yield call(getHomeVisitQuestionSchool, payload); 
        //数组自分组
        let groupResult = sortClass(result);
        let formatGroupResult:SchoolHomeVisitQesItemFormat[][] = [];
        //在子分组中的每组中找出最大值加到属性中（maxCount）
        for(let i of groupResult){
          let countArr = []
          for(let j of i){
            countArr.push(j.count)  
          }
          let maxCount = Math.max(...countArr)
          let newItem:SchoolHomeVisitQesItemFormat = {
            maxCount:0,
            opt:'',
            count:0,
            title:''
          }
          let newArr:SchoolHomeVisitQesItemFormat[] = []
          for(let j of i){
            j.maxCount = maxCount
            newItem = j
            newArr.push(newItem)
          }
          formatGroupResult.push(newArr)
        }
        yield put({
          type: 'setState',
          payload: {
            schoolHomeVisitQes: formatGroupResult,
          },
        });
      }catch (error) {}
    }
  },
};
export default SchoolHomeVisitModel;