/*
 * @Description:
 * @Author: tianxin
 * @Date: 2021-07-15 17:13:38
 * @LastEditors: jiaojinhui
 * @LastEditTime: 2021-11-25 17:08:33
 */
import { request } from '@/utils/request'

const baseUrl = '/upmsx'

/**
 * 获取内容详情
 */
export async function getWechatInfo(url: string) {
	return request(`${baseUrl}/offical/accounts/ticket`, {
		method: 'post',
		data: {
			url: url,
			appId: 'wx968dcdf89a944198',
		},
	})
}

/**
 * 家长注册-获取老师班级列表
 */
export async function getClassListByTeacherPhone(params: Record<string, any>) {
	return request(`${baseUrl}/v3/app/class/list`, {
		method: 'GET',
		params,
	})
}
