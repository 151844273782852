import { getWechatInfo } from '@/services/api/AppFamily/upmsx'
import { qiNiuToken } from '@/services/api/AppManager/auth'
/*
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-07-22 09:38:12
 * @LastEditors: Gao DeLong
 * @LastEditTime: 2021-08-23 15:51:34
 */
export type CommonModelState = {
}

interface CommonModelType {
	namespace: string
	state: CommonModelState
	effects: {
		getWechatInfo: Effect
		getQiNiuToken: Effect
	}
	reducers: {
		setState: Reducer<CommonModelState>
	}
}

const CommonModel: CommonModelType = {
	namespace: 'common',
	state: {},
	effects: {
		*getWechatInfo({ callback, url }, { call, put }) {
			try {
				const result = yield call(getWechatInfo, url)
				callback && callback(result)
			} catch (error) {}
		},
		/**
		 * @description 获取七牛Token
		 * @returns {MenuDataItem}
		 */
		*getQiNiuToken({ payload }, { call }) {
			let qiNiuInfo: Record<string, any> = yield call(qiNiuToken, payload)
			return qiNiuInfo
		},
	},
	reducers: {
		setState(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
}

export default CommonModel
