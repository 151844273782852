/*
 * @Description: 共建内容
 * @Author: heyat
 * @Date: 2021-07-23 09:51:40
 * @LastEditors: heyat
 * @LastEditTime: 2021-07-30 15:12:46
 */
import {
  getTopicList,
  getSchoolRanking,
  getAreaRanking,
} from "@/services/api/AppManager/dhapi";
/**
 * @description: 数据处理
 * @param {*}
 * @return {*}
 */
const deelData = (data: [], typeName: string) => {
  if (data.length > 0) {
    let tempArr: any[] = [];
    let dataArr: any[] = [];
    var newArr:
      | { name: any; value: any[]; areaStyle: any }[]
      | { value: any[] }[] = [];
    data.forEach((item: any, index: number) => {
      let tagName = typeName === "area" ? item.type : item.type;
      const color = ["#3893CB", "#3894CB", "#7D0FF4"];
      if (tempArr.indexOf(tagName) === -1) {
        newArr.push({
          name: tagName,
          value: [item.count],
          areaStyle: {
            color: color[index],
          },
        });
        tempArr.push(tagName);
      } else {
        newArr[tempArr.indexOf(tagName)].value.push(item.count);
      }
      if (dataArr.indexOf(item.tag) === -1) {
        dataArr.push(item.tag);
      }
    });
    const result = {
      data: newArr,
      nameList: dataArr,
    };
    return result;
  }
};
export type CooperateReportState = {
  topicInfo: APP_MANAGER_PARAMS.topicInfo;
  topData: APP_MANAGER_PARAMS.topicList;
  rankListAll:APP_MANAGER_PARAMS.rankListAll
};
interface CooperateReportModelType {
  namespace: string;
  state: CooperateReportState;
  effects: {
    getTopicList: Effect;
    getTop: Effect;
    getRankList: Effect;
  };
  reducers: {
    setState: Reducer<CooperateReportState>;
  };
}
const CooperateReportModel: CooperateReportModelType = {
  namespace: "cooperateReport",
  state: {
    topicInfo: {
      data: [],
      indicator: [],
      nameList: [],
    },
    topData: [],
    rankListAll:[[]]
  },
  effects: {
    /**
     * @description: 获取头部整体数据
     * @param {*}
     * @return {*}
     */
    *getTop({ param, typeNames }, { call, put }) {
      try {
        const value = yield call(getTopicList, { section: param.section }); // 区域
        let chartAllList: {
          tag: string;
          section: number;
          count: number;
          type: string;
        }[] = [];
        value.forEach(
          (item: {
            tag: string;
            section: number;
            count: number;
            type: string;
          }) => {
            if (typeNames === "area" && item.section === 0) {
              chartAllList.push(item);
            }
          }
        );
        yield put({
          type: "setState",
          payload: {
            topData: chartAllList,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description: 获取图表
     * @param {*}
     * @return {*}
     */
    *getTopicList({ param, typeNames }, { call, put }) {
      try {
        const value = yield call(getTopicList, { section: param.section }); // 区域
        let list: any = [];
        let chartAllList: {
          tag: string;
          section: number;
          count: number;
          type: string;
        }[] = [];

        value.forEach(
          (item: {
            tag: string;
            section: number;
            count: number;
            type: string;
          }) => {
            let tagName = typeNames === "area" ? item.tag : item.tag;
            if (tagName !== "0") {
              list.push(item);
            } else if (typeNames === "school" && item.section !== 0) {
              chartAllList.push(item);
            }
          }
        );
        const topicList = deelData(list, typeNames);
        if (typeNames === "area") {
          yield put({
            type: "setState",
            payload: {
              topicInfo: topicList,
            },
          });
        } else {
          yield put({
            type: "setState",
            payload: {
              topicInfo: topicList,
              topData: chartAllList,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @description: 获取内容贡献排行
     * @param {*}
     * @return {*}
     */
    *getRankList({ param, typeNames }, { call, put }) {
      try {
        let value =[]
        let tempArr: any[]=[]
        if(typeNames === "area"){
          value = yield call(getAreaRanking, { bureauId: param.id }); // 区域
          let arr1: any[]=[]
          let arr2: any[]=[]
          let arr3: any[]=[]
          let arr4: any[]=[]
          value.forEach(
            (item: {
              name: string;
              section: number;
              count: number;
            },index:number) => {
              if (typeNames === "area") {
                // chartAllList.push(item);
               if(item.section===1){
                 arr1.push(item)
               }else if(item.section===2){
                 arr2.push(item)
               }else if(item.section===3){
                 arr3.push(item)
               }else{
                 arr4.push(item)
               }
              }
              
            }
          );
          tempArr.push(arr4)
          tempArr.push(arr3)
          tempArr.push(arr2)
          tempArr.push(arr1)
        }else{
          value = yield call(  getSchoolRanking, { schoolId: param.id }); // 学校
          tempArr.push(value)

        }

        yield put({
          type: "setState",
          payload: {
            rankListAll: tempArr,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  reducers: {
    // 设置简单通用数据
    setState(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
export default CooperateReportModel;
