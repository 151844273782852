/*
 * @Description:  家长端model
 * @Author: Gao DeLong
 * @Date: 2021-07-06 15:13:25
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-11-04 10:10:48
 */

import { getSuperAdminInfo, saveBureau, saveTeacher } from "@/services/api/AppManager/dhapi"

export type formState = {
  yourName: string,
	//手机号
	phoneValue: string
	//验证码
	codeValue: string
}
export type RegestModelState = {
	//超管姓名
	adminName: string | null
	// 组织名称
	name: string | null
	form: formState
	//手机号错误提示
	hasError: boolean
}

interface RegestModelType {
	namespace: string
	state: RegestModelState
	effects: {
		changeState: Effect
		getSuperAdminInfo: Effect
		saveTeacher: Effect
		saveBureau: Effect
	}
	reducers: {
		setState: Reducer
	}
}

const InviteModel: RegestModelType = {
	namespace: 'invite',
	state: {
	  //超管姓名
    adminName: '',
    // 组织名称
    name: '',
		//手机号
		form: {
      yourName: '',
			phoneValue: '',
			codeValue: '',
		},
		//手机号错误提示
		hasError: false,
	},
	effects: {
    /**
		 * @description: 加入学校/教育局-超管信息
		 * @param {*}
		 * @return {*}
		 */
		*getSuperAdminInfo({ payload, userName }, { call, put }) {
			try {
				const result = yield call(getSuperAdminInfo, payload)
				// 用户姓名
				result['adminName'] = userName
				yield put({
          type: 'setState',
          payload: result,
        })
			} catch (error) {}
		},
    /**
		 * @description: 新增学校教师
		 * @param {*}
		 * @return {*}
		 */
		*saveTeacher({ payload}, { call, put }) {
			try {
				const result = yield call(saveTeacher, payload)
				return result
			} catch (error) {}
		},
    /**
		 * @description: 新增教育局普通成员
		 * @param {*}
		 * @return {*}
		 */
		*saveBureau({ payload}, { call, put }) {
			try {
				const result = yield call(saveBureau, payload)
				return result
			} catch (error) {}
		},

		*changeState({ payload }, { put }) {
			yield put({
				type: 'setState',
				payload: payload,
			})
		},
	},
	reducers: {
		setState(state, { payload }) {
			return {
				...state,
				...payload,
			}
		},
	},
}

export default InviteModel
